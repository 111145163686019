.s-about-quote-string {
  padding-top: 6.5rem;
  padding-bottom: 6.5rem;
}

.s-about-quote-string__title {
  position: relative;
  font-size: 3.5rem;
  text-transform: uppercase;
  line-height: 4rem;
  letter-spacing: -0.0267857143em;
}

.s-about-quote-string__star {
  position: absolute;
  top: 7rem;
  right: 19.8125rem;
  float: right;
  height: 100%;
  max-height: 8rem;
  width: 100%;
  max-width: 8rem;
  --tw-scale-x: 1.1;
  --tw-scale-y: 1.1;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  -ms-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@-webkit-keyframes pulse {
  50% {
    opacity: .5;
  }
}

@keyframes pulse {
  50% {
    opacity: .5;
  }
}

.s-about-quote-string__star {
  -webkit-animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

.s-about-quote-string__star img {
  --tw-rotate: 19deg;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  -ms-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  -o-object-fit: cover;
  object-fit: cover;
}

.s-about-quote-string__spin-wrapper {
  white-space: nowrap;
}

@media (max-width: 79.9988em) {
  .s-about-quote-string__star {
    position: static;
    padding-top: 2rem;
    padding-right: 0.5rem;
  }
}

@media (max-width: 47.9988em) {
  .s-about-quote-string {
    padding-top: 4rem;
    padding-bottom: 4rem;
    font-size: 4rem;
  }

  .s-about-quote-string__title {
    font-size: 1.75rem;
    line-height: 2.75rem;
    letter-spacing: 0;
  }
}

@media (max-width: 35.998em) {
  .s-about-quote-string__star {
    max-width: 6.5rem;
  }
}

@media (max-width: 23.4375em) {
  .s-about-quote-string__title {
    font-size: 1.25rem;
  }

  .s-about-quote-string__star {
    max-width: 4rem;
  }
}